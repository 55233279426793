<template>
    <div class="w-full h-full px-3 py-4">

        <div class="h-auto w-full rounded-lg shadow_box flex flex-col px-2 py-4 mb-6" :class="'bg-module-'+mode">

            <!-- <div class="h-16 flex flex-row">

                <div class="h-full w-10/12">

                    <div class="h-full w-full flex flex-col justify-center items-start px-2 truncate">
                        <span class="text-lg font-semibold truncate" :class="'text-dfont-'+mode">{{client.Name}}</span>
                        <span v-if="zona" class="text-xs text-gray">{{$t('zone')}} {{zona.Name}}</span>
                    </div>

                </div>

                <div class="h-full w-2/12 flex flex-row justify-end items-start">
                    <i class="mdi mdi-dots-vertical text-gray text-xl" @click="tofilters()"></i>
                </div>

            </div> -->

            <div v-if="rolpermisions" class="absolute bottom-0 right-0 h-auto flex w-auto z-30 m-2" >

                <div class="h-10 w-10 mx-2 rounded-full bg-red flex flex-row justify-center items-center" @click="toComments()">
                    <i class="mdi mdi-note-outline text-white text-xl font-semibold"></i>
                </div>

                <div class="h-10 w-10 rounded-full bg-red flex flex-row justify-center items-center" @click="toTops()">
                    <i class="mdi mdi-filter-variant text-white text-xl font-semibold"></i>
                </div>

            </div>
<!-- 
            <div v-if="rolpermisions" class="h-8 w-full rounded-lg flex flex-row justify-start items-center px-4" :class="'bg-box-'+mode" @click="toComments()">
            
                <i class="mdi mdi-note-outline mr-2" :class="'text-dfont-'+mode"></i>
                <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{ $t('comments') }}</span>
            
            </div> -->

            <!-- <div v-if="rolpermisions" class="h-10 w-full flex flex-row px-2 mt-4">

               <div class="h-full w-1/2 p-1">
                <div class="h-full rounded-lg flex flex-row justify-center items-center" :class="'bg-box-'+mode" @click="toTops()">
                    <i class="mdi mdi-filter-variant text-purple"></i>
                </div>
               </div>

               <div class="h-full w-1/2 p-1">
                <div class="h-full rounded-lg flex flex-row justify-center items-center" :class="'bg-box-'+mode" @click="toComments()">
                    <i class="mdi mdi-note-outline text-purple"></i>
                </div>
               </div>

            </div> -->
            <!-- <div class="flex mt-4 justify-start items-center">
                <div class="h-8 w-auto px-2 flex flex-row justify-start items-center">
                    <span class="text-md mr-1 font-semibold" :class="'text-dfont-'+mode">KPI's </span>
                    <span class="text-md text-purple font-semibold">{{period}}</span>
                </div>
                <span v-if="periodo == 'Mensual'" class="text-md text-gray">Mes: <span class="text-md text-darkgray font-semibold capitalize">{{ monthName }}</span></span>
            </div> -->


            <All :model="model" :immediate="true" :config="config" v-slot="{data, loading}">

                <div v-if="!loading" class="h-auto">

                    <div class="h-auto mt-8 flex flex-col">
                        <div class="h-auto flex flex-row justify-between items-center px-2">
                            <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('billing')}}</span>
                            <span class="text-md font-medium" :class="'text-dfont-'+mode">{{data.SalesTotal | reduceBigNumbers()}} €</span>
                        </div>
                        <div class="h-24">
                            <columnchart :data="data.Sales" text_option="billing" chartId="chartId" />
                        </div>
                    </div>

                    <div class="h-auto mt-10 flex flex-col">
                        <div class="h-auto flex flex-row justify-between items-center px-2">
                            <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('meters')}}</span>
                            <span class="text-md font-medium" :class="'text-dfont-'+mode">{{data.MetersTotal | reduceBigNumbers()}} m<sup>2</sup></span>
                        </div>
                        <div class="h-24">
                            <columnchart :data="data.Meters" text_option="billing" chartId="chartId" />
                        </div>
                    </div>

                    <div class="h-auto mt-10 flex flex-col">
                        <div class="h-auto flex flex-row justify-between items-center px-2">
                            <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('margin')}}</span>
                            <span class="text-md font-medium" :class="'text-dfont-'+mode">{{data.MarginTotal| reduceBigNumbers()}} %</span>
                        </div>
                        <div class="h-24">
                            <columnchart :data="data.Margin" text_option="billing" chartId="chartId" />
                        </div>
                    </div>

                    <div class="h-auto mt-10 flex flex-col">
                        <div class="h-auto flex flex-row justify-between items-center px-2">
                            <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('averagePrice')}}</span>
                            <span class="text-md font-medium" :class="'text-dfont-'+mode">{{data.MediumPriceTotal | reduceBigNumbers()}} €/m<sup>2</sup></span>
                        </div>
                        <div class="h-24">
                            <columnchart :data="data.MediumPrice" text_option="billing" chartId="chartId" />
                        </div>
                    </div>

                    <div class="h-auto flex flex-row justify-between items-center px-2 mt-10">
                        <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('unpaid')}}</span>
                        <span class="text-md font-medium" :class="'text-dfont-'+mode">{{data.UnpaidTotal| reduceBigNumbers()}} €</span>
                    </div>

                    <div class="h-auto flex flex-row justify-end items-center px-2">
                        <span class="text-xs text-orange">{{data.UnpaidSalesTotal| reduceBigNumbers()}} % / {{$t('billing')}}</span>
                    </div>

                    <div class="h-26 flex flex-col w-full rounded-xl shadow_box" :class="'bg-box-'+mode">

                        <div class="h-full flex flex-row justify-start items-center" >

                            <div class="h-full flex flex-row justify-start items-center w-6/12"></div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-2xs" :class="'text-font_gray-'+mode">{{yearantant}}</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-2xs" :class="'text-font_gray-'+mode">{{yearant}}</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-2xs" :class="'text-font_gray-'+mode">{{yearact}}</span>
                            </div>

                        </div>

                        <div class="h-full flex flex-row justify-start items-center">

                            <div class="h-full flex flex-row justify-start items-center w-6/12 ml-2">
                                <span class="text-xs" :class="'text-font_gray-'+mode">{{$t('unpaid')}} {{$t('year')}}</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-orange text-xs font-medium">{{data.Unpaid[yearantant] | reduceBigNumbers()}} €</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-orange text-xs font-medium">{{data.Unpaid[yearant] | reduceBigNumbers()}} €</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-orange text-xs font-medium">{{data.Unpaid[yearact] | reduceBigNumbers()}} €</span>
                            </div>

                        </div>

                        <div class="h-full flex flex-row justify-start items-center">
                            <div class="h-full flex flex-row justify-start items-center w-6/12 ml-2">
                                <span class="text-xs" :class="'text-font_gray-'+mode">% / {{$t('billing')}}</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{data.UnpaidSales[yearantant] | reduceBigNumbers()}}%</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{data.UnpaidSales[yearant] | reduceBigNumbers()}}%</span>
                            </div>
                            <div class="h-full flex flex-row justify-center items-center w-2/12">
                                <span class="text-xs font-medium" :class="'text-dfont-'+mode">{{data.UnpaidSales[yearact] | reduceBigNumbers()}}%</span>
                            </div>
                        </div>

                    </div>

                    <div class="h-auto mt-10 flex flex-col">
                        <div class="h-auto flex flex-row justify-between items-center px-2">
                            <div class="h-full flex flex-col justify-start items-start">
                                <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('promotionalExpenses')}}</span>
                                <span class="text-xs text-gray">({{$t('last12Months')}})</span>
                            </div>

                            <span class="text-md font-medium text-right leading-none" :class="'text-dfont-'+mode">{{ data.PromoTotal | reduceBigNumbers()}} € <br>  <span class="text-xs text-gray">{{data.PromoSaleTotal | reduceBigNumbers()}}% / {{$t('billing')}}</span></span>
                        </div>

                        <div class="h-24">
                            <columnchart :data="data.Promo" text_option="promotion" chartId="chartId" />
                            <!-- <chartarea :data="data.Promo"  text_option="promotion" chartId="chartId"></chartarea> -->
                        </div>
                    </div>

                    <div class="h-auto mt-10 flex flex-col mb-10">
                        <div class="h-auto flex flex-row justify-between items-center px-2">
                            <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('orderBook')}}</span>
                            <span class="text-md font-medium text-right leading-none" :class="'text-dfont-'+mode">{{data.TotalPendingOrderTotal| reduceBigNumbers()}} € <br> 
                                <span class="text-xs text-gray">{{$t('currentValue')}}</span></span>
                        </div>
                        <div class="h-24">
                            <columnchart :data="data.TotalPendingOrder" text_option="orderBook" chartId="chartId" />
                            <!-- <chartarea :data="data.TotalPendingOrder" text_option="orderBook" chartId="chartId"></chartarea> -->
                        </div>
                    </div>

                    <All :model="model2" :immediate="true" :config="config" v-slot="{data:data2, loading:loading2}">

                        <div v-if="!loading2" class="h-auto w-full">

                            <div class="h-auto mt-10 flex flex-col">
                                <div class="h-auto flex flex-row justify-between items-center px-2">
                                    <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('annualSalesHistory')}}</span>
                                    <span class="text-md font-medium" :class="'text-dfont-'+mode">{{data.MediumPriceTotal | reduceBigNumbers()}} €/m<sup>2</sup></span>
                                </div>
                                <div class="h-24">
                                    <columnchart :data="data2.Client.Historical" text_option="billing" chartId="chartId" />
                                    <!-- <chartarea :data="data.MediumPrice" text_option='averagePrice' chartId="chartId"></chartarea> -->
                                </div>
                            </div>

                            <div class="h-auto flex flex-row justify-start items-center px-2 mt-10">
                                <span class="text-md font-bold" :class="'text-dfont-'+mode">{{$t('aboutTheClient')}}</span>
                            </div>

                            <div class="h-10 my-1">
                                <div class="h-full rounded flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                                    <span class="text-xs font-bold" :class="'text-dfont-'+mode">{{$t('averagePriceOfYourPurchases')}}</span>
                                    <span class="font-medium text-sm" :class="'text-dfont-'+mode">{{data2.Client.MediumPrice | reduceBigNumbers()}} €/m<sup>2</sup></span>
                                </div>
                            </div>

                            <div class="h-10 my-1">
                                <div class="h-full rounded flex flex-row justify-between items-center px-2" :class="'bg-box-'+mode">
                                    <div class="h-full w-5/6 flex flex-col justify-center items-start">
                                        <span class="text-xs font-bold" :class="'text-dfont-'+mode">{{$t('closeLastPurchase')}}</span>
                                    </div>
                                    <span class="font-medium text-sm" :class="'text-dfont-'+mode">{{data2.Client.LastSale | moment('DD/MM/YYYY')}}</span>
                                </div>
                            </div>

                            <div class="h-auto px-2 my-1 rounded" :class="'bg-box-'+mode">

                                <div class="h-10 flex flex-row justify-between items-center">
                                    <span class="text-xs font-bold" :class="'text-dfont-'+mode">{{$t('mostPurchasedFamily')}} (€)</span>
                                </div>

                                <div class="h-auto px-2 max-h-20 overflow-auto">

                                    <div  v-for="(el, index) in data2.Client.mostBoughtFamily" :key="index" class="h-10 w-full flex flex-row justify-end items-center">
                                        <span class="font-medium text-sm truncate" :class="'text-dfont-'+mode">{{el.Family}}</span>
                                    </div>

                                </div>

                            </div>

                            <div class="h-auto px-2 my-1 rounded" :class="'bg-box-'+mode">

                                <div class="h-10 flex flex-row justify-between items-center">
                                    <span class="text-xs font-bold" :class="'text-dfont-'+mode">{{$t('mostPurchasedFormats')}} (€)</span>
                                </div>

                                <div class="h-auto px-2 max-h-20 overflow-auto">

                                    <div  v-for="(el, index) in data2.Client.mostBoughtFormat" :key="index" class="h-10 w-full flex flex-row justify-end items-center">
                                        <span class="font-medium text-sm truncate" :class="'text-dfont-'+mode">{{el.Format}}</span>
                                    </div>

                                </div>

                            </div>

                            <div class="h-8"></div>

                        </div>

                    </All>

                </div>

                <div v-else class="h-32 relative">
                    <loader :loading="loading"></loader>
                </div>

            </All>


        </div>

    </div>
</template>

<script>
    import { state } from '@/store';
    import chartarea from '../../components/chartarea.vue';
    import donutchart from '../../components/donutchart.vue';
    import columnchart from '../../components/columnchart.vue';
    import { All } from '@/api/components';
    import loader from '../../components/loader.vue';

    export default {
        name: 'client',
        components:{
            chartarea,
            donutchart,
            columnchart,
            All,
            loader
        },
        data(){
            return{
                yearact: this.$moment().format('YYYY'),
                yearant: this.$moment().subtract(1, 'years').format('YYYY'),
                yearantant: this.$moment().subtract(2, 'years').format('YYYY')
            }
        },
        methods:{
            toTops(){

                this.$router.push({ name: 'comercialclienttops' })
            },
            toComments(){
                this.$router.push({name: 'comments'})
            },
            tofilters(){
                this.$router.push({name: 'clientfilters'})
            }
        },
        computed:{
            monthName(){
                return this.$moment({M: state.month -1 }).format('MMMM')
            },
            client(){
                return state.clientSelected
            },
            model(){

                if(this.user.rol.Name != 'Representante'){

                    if(state.comercialSelected && state.comercialSelected.Id){

                        return 'KpiGraphsComercialClientUser'

                    } else {

                        return 'KpiGraphsComercialClientZone'
                    }

                } else {

                    return 'KpiclientrepresentantZone'

                }
            },
            model2(){

                if(this.user.rol.Name != 'Representante'){

                    if(state.comercialSelected && state.comercialSelected.Id){

                        return 'KpiGraphsComercialClientUser2'

                    } else {

                        return 'KpiGraphsComercialClientZone2'
                    }

                } else {

                    return 'KpiclientrepresentantZone2'

                }
            },
            user(){
                return state.user
            },
            config(){

                if(this.user.rol.Name != 'Representante'){

                    if(this.user.rol.Name != 'Comercial'){

                        if(state.comercialSelected && state.comercialSelected.Id){

                            if(state.clientSelected && state.clientSelected.Id){
                                return{
                                    data:{
                                        period: state.period,
                                        Company: state.idCompany,
                                        SaleType: state.saleTypeSelected,
                                        IdUser: state.comercialSelected.Id,
                                        Client: state.clientSelected.Id,
                                        month: state.month
                                    }
                                }
                            }else{

                                return{
                                    data:{
                                        period: state.period,
                                        Company: state.idCompany,
                                        SaleType: state.saleTypeSelected,
                                        IdUser: state.comercialSelected.Id,
                                        Client: state.clientSelected.IdClient,
                                        month: state.month
                                    }
                                }

                            }

                        } else {

                            if(state.clientSelected && state.clientSelected.Id){

                                return{
                                    data:{
                                        period: state.period,
                                        Company: state.idCompany,
                                        SaleType: state.saleTypeSelected,
                                        Zone: state.idZone,
                                        Client: state.clientSelected.Id,
                                        month: state.month
                                    }
                                }

                            }else{

                                return{
                                    data:{
                                        period: state.period,
                                        Company: state.idCompany,
                                        SaleType: state.saleTypeSelected,
                                        Zone: state.idZone,
                                        Client: state.clientSelected.IdClient,
                                        month: state.month
                                    }
                                }
                            }

                        }

                    } else {

                        return{
                            data:{
                                period: state.period,
                                Company: state.idCompany,
                                SaleType: state.saleTypeSelected,
                                Client: state.clientSelected.Id,
                                // IdUser: state.user.id
                                Zone: state.idZone,
                                month: state.month
                            }
                        }

                    }

                } else {

                    return{
                        data:{
                            period: state.period,
                            Company: state.idCompany,
                            SaleType: state.saleTypeSelected,
                            Zone: state.idZone,
                            Client: state.clientSelected.Id,
                            Employee: state.idEmployeeSelected,
                            month: state.month
                        }
                    }

                }
            },
            zoneselected(){
                return state.zoneSelected
            },
            comercial(){
                return state.comercialSelected
            },
            period(){
                switch (state.period) {
                    case 'month':
                        return this.$t("monthly")
                        break;

                    case 'quarter':
                        return this.$t("quarterly")
                        break;

                    case 'year':
                        return this.$t("annual")
                        break;
                }
            },
            employee(){
                return state.employeeSelected
            },
            rolpermisions(){

                if(this.rol == 'Propiedad'){

                    return true

                } else if(this.rol == 'Gerente'){

                    return true

                } else if(this.rol == 'Comercial'){

                    return true

                } else if(this.rol == 'Representante'){

                    return true

                } else {

                    return false

                }

            },
            rol(){
                return state.user.rol.Name
            },
            zona(){
                return state.zone
            },
            mode(){
                return state.mode
            }
        },
        mounted(){

        }
    }
</script>
